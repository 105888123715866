import './styles/App.css';
import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import Landing from './pages/signup';

function App() {
  return (
    <>
      <Helmet>
        <title>StockAide - AI-Powered Stock Analysis</title>
        <meta name="description" content="StockAide uses artificial intelligence to provide personalized stock market insights, technical analysis, and investment recommendations." />
        
        {/* Favicon */}
        <link rel="icon" href="/favicon.ico" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://mystockaide.com/" />
        <meta property="og:title" content="StockAide - AI-Powered Stock Analysis" />
        <meta property="og:description" content="StockAide uses artificial intelligence to provide personalized stock market insights, technical analysis, and investment recommendations." />
        <meta property="og:image" content="/logo.png" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content="https://mystockaide.com/" />
        <meta name="twitter:title" content="StockAide - AI-Powered Stock Analysis" />
        <meta name="twitter:description" content="StockAide uses artificial intelligence to provide personalized stock market insights, technical analysis, and investment recommendations." />
        <meta name="twitter:image" content="/logo.png" />

        {/* Additional SEO tags */}
        <meta name="keywords" content="stock analysis, AI stocks, investment, trading, financial analysis, stock market" />
        <meta name="author" content="StockAide" />
        <link rel="canonical" href="https://mystockaide.com" />
      </Helmet>

      <Router>
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/" element={<Landing />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
import React, { useState } from 'react';
import { ArrowRight } from 'lucide-react';
import StockAIdeLogo from '../images/StockAIdeSmallBlank.png';

const Landing = () => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    
    try {
      const response = await fetch('/api/signup/early', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      
      const data = await response.json();
      
      if (response.ok) {
        setStatus('success');
        setEmail('');
      } else {
        setStatus(data.message || 'error');
      }
    } catch (error) {
      setStatus('An error occurred. Please try again.');
    }
    
    setIsLoading(false);
  };

  return (
    <div className="min-h-screen bg-stone-950 text-white">
      {/* Navigation */}
      <nav className="fixed top-0 w-full bg-black/50 backdrop-blur-lg border-b border-zinc-800 z-50">
        <div className="max-w-7xl mx-auto px-0">
          <div className="h-16 flex items-center">
          <div className="w-72 h-64 flex items-center justify-start pl-0">
              <img 
                  src={StockAIdeLogo}
                  alt="StockAIde Logo" 
                  className="w-full h-full object-contain"
              />
          </div>
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <main className="pt-32 pb-16">
        <div className="max-w-[750px] mx-auto px-4">
          <div className="space-y-12">
            {/* Sign Up Section */}
            <div className="text-center space-y-4">
              <h1 className="text-4xl md:text-5xl font-bold tracking-tight bg-gradient-to-r from-neutral-50 to-neutral-100 text-transparent bg-clip-text">
                AI-Powered Stock Predictions
              </h1>
              <p className="text-2xl font-semibold text-zinc-200 mt-2">
                Stay Ahead of the Market
              </p>
              <h2 className="text-3xl font-bold tracking-tight mt-12">
                Get Early Access
              </h2>
              <p className="text-zinc-400">
                Join our early access program and receive an extended free trial at launch. 
                <br></br>
                Be among the first to leverage AI for smarter investing.
              </p>
            </div>

            {/* Form */}
            <div className="bg-zinc-900/50 backdrop-blur border border-zinc-800 rounded-xl p-6">
              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <input
                    type="email"
                    required
                    className="w-full px-4 py-3 bg-black/50 border border-zinc-800 rounded-lg focus:ring-2 focus:ring-zinc-700 focus:border-zinc-700 transition-colors placeholder:text-zinc-600"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <button
                  type="submit"
                  disabled={isLoading}
                  className="w-full flex items-center justify-center px-4 py-3 rounded-lg bg-white text-black hover:bg-zinc-200 transition-colors disabled:opacity-50 disabled:hover:bg-white font-medium"
                >
                  {isLoading ? (
                    'Signing up...'
                  ) : (
                    <>
                      Sign Up
                      <ArrowRight className="ml-2 h-4 w-4" />
                    </>
                  )}
                </button>
              </form>

              {status === 'success' && (
                <div className="mt-4 p-3 bg-green-500/10 border border-green-500/20 rounded-lg">
                  <p className="text-sm text-green-500 text-center">
                    Thanks for signing up! We'll notify you when we launch.
                  </p>
                </div>
              )}
              
              {status !== 'success' && status && (
                <div className="mt-4 p-3 bg-red-500/10 border border-red-500/20 rounded-lg">
                  <p className="text-sm text-red-500 text-center">
                    {status}
                  </p>
                </div>
              )}
              {/* New Benefits Section */}
              <div className="mt-8 space-y-4 border-t border-zinc-800 pt-6">
                <p className="text-zinc-300 font-medium">Early access members receive:</p>
                <div className="space-y-3">
                  <div className="flex items-start gap-3">
                    <div className="h-6 w-6 flex-shrink-0 bg-yellow-500/10 border border-yellow-500/20 rounded-full flex items-center justify-center">
                      <span className="text-yellow-500 text-sm">✓</span>
                    </div>
                    <p className="text-zinc-400 text-sm leading-tight">
                      <span className="text-zinc-300 font-medium">Extended Free Trial Access</span><br/>
                      Get an exclusive 30-day trial period to explore all premium features
                    </p>
                  </div>

                  <div className="flex items-start gap-3">
                    <div className="h-6 w-6 flex-shrink-0 bg-yellow-500/10 border border-yellow-500/20 rounded-full flex items-center justify-center">
                      <span className="text-yellow-500 text-sm">✓</span>
                    </div>
                    <p className="text-zinc-400 text-sm leading-tight">
                      <span className="text-zinc-300 font-medium">Priority Launch Access</span><br/>
                      Be first in line when we launch, with immediate access to our AI predictions
                    </p>
                  </div>

                  <div className="flex items-start gap-3">
                    <div className="h-6 w-6 flex-shrink-0 bg-yellow-500/10 border border-yellow-500/20 rounded-full flex items-center justify-center">
                      <span className="text-yellow-500 text-sm">✓</span>
                    </div>
                    <p className="text-zinc-400 text-sm leading-tight">
                      <span className="text-zinc-300 font-medium">Data-Driven Investment Insights</span><br/>
                      Access AI-powered analysis based on comprehensive market data for informed decision making
                    </p>
                  </div>

                  <div className="flex items-start gap-3">
                    <div className="h-6 w-6 flex-shrink-0 bg-yellow-500/10 border border-yellow-500/20 rounded-full flex items-center justify-center">
                      <span className="text-yellow-500 text-sm">✓</span>
                    </div>
                    <p className="text-zinc-400 text-sm leading-tight">
                      <span className="text-zinc-300 font-medium">Early Adopter Benefits</span><br/>
                      Shape the future of StockAIde with direct input on features and development
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* About Section */}
            <div className="space-y-4 text-center">
              <h2 className="text-lg font-semibold">About StockAIde</h2>
              <div className="bg-zinc-900/50 backdrop-blur border border-zinc-800 rounded-xl p-6">
                <p className="text-zinc-200 text-sm text-start leading-relaxed">
                Founded by a forward-thinking computer science senior, StockAIde leverages advanced 
                artificial intelligence to deliver accessible, data-driven stock predictions that help 
                everyday investors make more informed decisions in the US stock market. Our mission is to 
                democratize the power of AI-driven market analysis, making it available to beginner investors, 
                not just major institutions. While we're a small, independent project, we're taking the time 
                to ensure thorough testing and validation before launch - because your financial future matters. 
                Early access members will be instrumental in shaping this platform, helping us create a tool 
                that makes sophisticated market insights understandable and actionable for everyone.
                </p>
              </div>
            </div>
            {/* Legal and Additional Info Section */}
            <div className="space-y-8">
              {/* Timeline */}
              <div className="text-center">
                <p className="text-zinc-400 text-sm">Expected Launch: December 2024</p>
              </div>

              {/* Contact and Social */}
              <div className="space-y-4">
                <div className="flex justify-center gap-6">
                  <a href="https://x.com/StockAIWeb" className="text-zinc-400 hover:text-yellow-500 transition-colors">
                    Twitter
                  </a>
                  <a href="https://www.instagram.com/stockaide_us/" className="text-zinc-400 hover:text-yellow-500 transition-colors">
                    Instagram
                  </a>
                </div>
                <div className="text-center">
                  <p className="text-zinc-400 text-sm">Questions? Contact us at:</p>
                  <a href="mailto:contact@stockaide.com" className="text-yellow-500 hover:text-yellow-400 text-sm">
                    contact@mystockaide.com
                  </a>
                </div>
              </div>

              {/* Privacy Notice */}
              <div className="text-center">
                <p className="text-zinc-500 text-xs">
                  We respect your privacy. Email us at anytime to unsubscribe.
                </p>
              </div>

              {/* Legal Disclaimer */}
              <div className="bg-black/30 rounded-lg p-4 max-w-2xl mx-auto">
                <p className="text-zinc-500 text-xs text-center leading-relaxed">
                  *Trading stocks carries inherent risks. StockAIde's predictions are based on AI analysis 
                  of historical data and should not be considered as financial advice. Past performance 
                  does not guarantee future results. Please invest responsibly and consider consulting with 
                  a financial advisor before making investment decisions.
                </p>
              </div>
            </div>

            {/* Add this after your main content div to create a sticky footer */}
            <footer className="bg-black/50 backdrop-blur-lg border-t border-zinc-800 py-4 mt-16">
              <div className="max-w-[750px] mx-auto px-4">
                <p className="text-zinc-500 text-xs text-center">
                  © 2024 StockAIde. All rights reserved.
                </p>
              </div>
            </footer>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Landing;
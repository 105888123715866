import React from 'react';
import HomeComponent from '../components/homeComponent';

const Home = () => {
    return (
        <div>
            <h1>Welcome to StockAIde</h1>
            <HomeComponent />
        </div>
    );
};

export default Home;